<template>
  <div>
    <div class="card card-primary">
      <div class="card-header pt-2 pb-2">
        <a
          data-toggle="collapse"
          href="#lineas_negocio"
          aria-expanded="true"
          id="heading-lineas_negocio"
          class="d-block"
        >
          <i class="fa fa-chevron-down pull-right"></i>
          Lineas Negocio
        </a>
      </div>
      <div id="lineas_negocio" class="coollapse">
        <div class="card-body">
          <div class="row" v-for="linea in listasForms.lineas" :key="linea.id">
            <div class="form-group">
              <div
                class="
                  custom-control
                  custom-switch
                  custom-switch-off-secundary
                  custom-switch-on-success
                "
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'linea_' + linea.id"
                  @change="actualizarLinea(linea.id)"
                  v-model="form.linea[linea.id]"
                  :checked="linea.estado"
                />
                <label
                  class="custom-control-label"
                  :for="'linea_' + linea.id"
                  >{{ linea.nombre }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "TurnoLinea",
  data() {
    return {
      form: {
        linea: [],
      },
      listasForms: {
        lineas: [],
      },
      cargando: false,
    };
  },
  methods: {
    actualizarLinea(linea) {
      axios
        .put("/api/admin/turnos/assingOrRevokeLinea", {
          turno: this.$parent.form.id,
          linea: linea,
          accion: this.form.linea[linea],
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },
    getLineasVehiculo() {
      let data = [];
      axios
        .get(
          "/api/admin/turnos/lineas?turno=" + this.$parent.form.id
        )
        .then((response) => {
          this.listasForms.lineas = response.data;

          for (let i = 0; i < this.listasForms.lineas.length; i++) {
            const linea = this.listasForms.lineas[i];
            this.form.linea[linea.id] = linea.estado;
          }
        });
    },
  },
  mounted() {
    this.getLineasVehiculo();
  },
};
</script>